<template>
  <div>
    <div class="bg-sp-dark bottom-radius">
        <div class="container">
          <div class="row py-5">
            <div class="text-center text-md-start col-md-8 py-3">
              <h2>Accountgegevens</h2>
              <h6 class="text-secondary">Pas hier jouw accountgegevens aan en bewerk de accounts van eventuele gezinsleden.</h6>
            </div>
          </div>
        </div>
    </div>
    <div class="bg-sp-light overlay-radius">
      <div class="container py-5">
            <div class="row">
            <div class="col-md-8">
              <h4 class="mb-3">Familiegegevens</h4>
                <div class="card">
                    <div class="card-body">
                        <div v-if="familie" class="row">
                          <div class="col-12">
                            <p>Alle velden met een <span class='text-danger'>*</span> zijn verplicht.</p>
                          </div>
                            <div class='col-md-6 pe-md-0'>
                                <div class="mb-3">
                                    <label for="tussenvoegsel" class="form-label">Tussenvoegsel</label>
                                    <input class='form-control' id='tussenvoegsel' tabindex="1" v-model="familie.prelastname" placeholder="Tussenvoegsel">
                                </div>
                                <div class="mb-3">
                                    <label for="telefoonnummer3" class="form-label">Mobiel telefoonnummer<span>*</span></label>
                                    <input class='form-control' id='telefoonnummer3' tabindex="3" v-model="familie.phone3" required='true' placeholder="Mobiel telefoonnummer">
                                </div>
                                <div class="mb-3">
                                    <label for="telefoonnummer2" class="form-label">Alternatief telefoonnummer</label>
                                    <input class='form-control' id='telefoonnummer2' tabindex="4" v-model="familie.phone2" placeholder="Alternatief telefoonnummer">
                                </div>
                                <div class="mb-3">
                                    <label for="telefoonnummer1" class="form-label">Telefoonnummer thuis</label>
                                    <input class='form-control' id='telefoonnummer1' tabindex="5" v-model="familie.phone1" placeholder="Telefoonnummer thuis">
                                </div>
                                 <div v-if='familie.email' class="mb-3">
                                    <label for="email" class="form-label">Geregistreerd emailadres</label>
                                    <h6 id='email'>Je maakt gebruik van <strong>{{familie.email}}</strong></h6>
                                </div>
                            </div>
                            <div class="col-md-6">
                                 <div class="mb-3">
                                    <label for="achternaam" class="form-label">Achternaam<span>*</span></label>
                                    <input class='form-control' id='achternaam' tabindex="2" v-model="familie.lastname" required='true' placeholder="Achternaam">
                                </div>
                                <div class="mb-3">
                                    <label for="adres" class="form-label">Adres<span>*</span></label>
                                    <input class='form-control' id='adres' tabindex="6" v-model="familie.adress" required='true' placeholder="Adres">
                                </div>
                                <div class="mb-3">
                                    <label for="postcode" class="form-label">Postcode<span>*</span></label>
                                    <input class='form-control' id='postcode' tabindex="7" v-model="familie.zipcode" required='true' placeholder="Postcode">
                                </div>
                                <div class="mb-3">
                                    <label for="woonplaats" class="form-label">Woonplaats<span>*</span></label>
                                    <input class='form-control' id='woonplaats' tabindex="8" v-model="familie.city" required='true' placeholder="Woonplaats">
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button
                                @click="saveForm()"
                                tabindex="9"
                                id='submit-button'
                                class="float-start float-md-end btn btn-primary btn-lg">
                                Opslaan
                                </button>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>

            <div class="col-md-4 mt-4 mt-md-0">
              <div class="w-100 d-flex align-items-center justify-content-between mb-3 ">
                <h4 class="mb-0">Gezinsleden</h4>
                <button id='gezinslid-toevoegen-knop' v-if='!gezinslidToevoegen && familie.clients.length > 0' class='btn btn-primary' @click='gezinslidToevoegen = true'>Gezinslid toevoegen</button>
              </div>

                <div class="card">
                  <div v-if='gezinslidToevoegen || familie.clients.length === 0' class='card-body' id="gezinslid-toevoegen">
                         <div class="mb-3">
                            <label for="voornaam" class="form-label">Voornaam<span>*</span></label>
                            <input class='form-control' id='voornaam' tabindex="9" v-model="client.firstname" placeholder="Voornaam">
                        </div>
                          <div class="mb-3">
                            <label for="geboortedatum" class="form-label">Geboortedatum<span>*</span></label>
                            <input class='form-control' type='date' id='geboortedatum' tabindex="10" v-model.lazy="geboortedatum" placeholder="Geboortedatum">
                        </div>
                        <div class='w-100 d-block'>

                            <button
                                @click="addClient()"
                                tabindex="11"
                                class="w-100 btn btn-primary p-3">
                                Toevoegen
                            </button>
                              <button
                              v-if='familie.clients.length > 0'
                                @click="gezinslidToevoegen = false; client = {}"
                                tabindex="12"
                                class="btn btn-light w-100 mt-2 p-3 btn-light">
                                Annuleren
                            </button>
                        </div>
                      </div>
                    <div v-if="familie.clients.length > 0" class="card-body">
                        <div v-if="familie" class="row">
                            <div class="col-12 d-grid" id='personen-grid'>
                                <div class="client-box d-flex justify-content-between align-items-center"
                                v-for="(lid, index) in familie.clients"
                                :key="index"
                                >
                                <div class="text-start">
                                  <h6><strong>{{lid.firstname}}</strong></h6>
                                    <h6 v-if='lid.birthdate'><small>{{moment(lid.birthdate).format('D MMMM YYYY')}}</small></h6>
                                    <h6 v-else class='badge bg-danger d-block mx-auto min-content-width'>Missende geboortedatum</h6>
                                </div>
                                <div>
                                  <button
                                      @click="$router.push({ name: 'PersoonBewerken', params: { id: lid.customerId } })"
                                      class="btn btn-light">
                                      Bewerken
                                  </button>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div v-if='familie.clients.length === 0'>
                            <h6>Je hebt nog geen gezinsleden</h6>
                        </div>
                    </div>
                    <div v-else  class="card-body">
                        <div id='banner' class="col-12 d-block d-md-inline-flex">
                            <div id="banner-icon">
                                <font-awesome-icon icon="fa-solid fa-plus" />
                            </div>
                            <div class='d-flex align-items-center justifyp-content-center'>
                                <div>
                                    <h2 class='mb-0'>Gezinsleden toevoegen</h2>
                                    <h5>Voeg eenvoudig leden toe aan jouw account, deze kun je altijd bewerken.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
              </div>
        </div>
    </div>
  </div>
</div>
</template>
<script>
import moment from 'moment'
import 'moment/locale/nl'
import { createToaster } from '@meforma/vue-toaster'

export default {
  name: 'Account',
  created: function () {
    this.moment = moment
  },
  props: {
    scrollDown: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      gezinslidToevoegen: false,
      client: {
        firstname: '',
        birthdate: ''
      }
    }
  },
  computed: {
    familie () {
      return this.$store.state.familie ? this.$store.state.familie.info : false
    },
    geboortedatum: {
      get: function () {
        return moment(this.client.birthdate).format('yyyy-MM-DD')
      },
      set: function (value) {
        this.client.birthdate = value
      }
    }
  },
  mounted () {
    const toaster = createToaster({ position: 'top', duration: 6000 })
    this.$store.dispatch('familie/getFamilie')
    if (this.scrollDown === 'true') {
      window.scrollTo(0, document.body.scrollHeight)
    }
    if (this.message) {
      toaster.show(this.message)
    }
  },
  methods: {
    async addClient () {
      const toaster = createToaster({ position: 'bottom-left', duration: 3000 })
      if (this.client.firstname === '' || this.client.birthdate === '') {
        toaster.error('Vul alle verplichte velden in')
        return
      }
      const c = this.client
      this.$store.dispatch('familie/addClient', c).then((res) => {
        if (res.status === 201) {
          toaster.show(`${c.firstname} toegevoegd!`)
          this.client = {
            firstname: '',
            birthdate: ''
          }
          this.gezinslidToevoegen = false
        } else {
          toaster.error(`Error, kon ${c.firstname} niet toevoegen`)
        }
      })
    },
    async saveForm () {
      const toaster = createToaster({ position: 'bottom-left', duration: 3000 })

      // check if all fields are filled out
      const checks = [
        'phone3',
        'lastname',
        'adress',
        'zipcode',
        'city'
      ]

      for (const check of checks) {
        if (!this.$store.state.familie.info[check] || this.$store.state.familie.info[check] === '') {
          toaster.error('Vul alle verplichte velden in')
          return
        }
      }

      // check if phone numbers are valid

      // remove all non numeric characters
      this.$store.state.familie.info.phone1 = this.$store.state.familie.info.phone1 ? this.$store.state.familie.info.phone1.replace(/\D/g, '') : null
      this.$store.state.familie.info.phone2 = this.$store.state.familie.info.phone2 ? this.$store.state.familie.info.phone2.replace(/\D/g, '') : null
      this.$store.state.familie.info.phone3 = this.$store.state.familie.info.phone3 ? this.$store.state.familie.info.phone3.replace(/\D/g, '') : null

      const phone1 = this.$store.state.familie.info.phone1 // telefoonnummer thuis
      const phone2 = this.$store.state.familie.info.phone2 // alternatief telefoonnummer
      const phone3 = this.$store.state.familie.info.phone3 // mobiel telefoonnummer <- required

      // a phone number should be 10 digits long
      if (phone3.length !== 10 || (phone2 && phone2.length !== 10) || (phone1 && phone1.length !== 10)) {
        toaster.error('Vul een geldig telefoonnummer in. (10 cijfers)')
        return
      }

      // check if zip code is valid
      const zip = this.$store.state.familie.info.zipcode
      if (!zip.match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i)) {
        toaster.error('Vul een geldige postcode in')
        return
      }

      this.$store.dispatch('familie/saveFamilie').then((res) => {
        if (res.status === 200) {
          toaster.show('Familie opgeslagen')
          this.$router.push({ name: 'Landing' })
        } else {
          toaster.error('Error, kon niet opslaan')
        }
      })
    }
  }
}
</script>
<style scoped>
#banner{
    background: var(--skipiste-color);
    color: #fff;
    padding: 1rem;
    border-radius: 10px;
}
#banner-icon{
    background: #fff;
    margin-right: 1rem;
    border-radius: 5px;
    height: 100px;
    width: 100px;
    font-size: 3rem;
    color:  var(--skipiste-color);
    display: flex;
    align-items: center;
    justify-content: center;
}
#banner h2{
    font-weight: bold;
}
.min-content-width{
    width: min-content;
}
#personen-grid{
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.9rem;
    grid-template-rows: auto;
}
.client-box{
    padding: 0;
    border-bottom: 0.5px solid rgba(0,0,0,.1);
    text-align: center;
    border-radius: 0px;
}

.client-box:last-child{
  border-bottom: none;
}

.enlarge-container{
    margin-right: calc(-1 * var(--bs-gutter-x,.75rem));
    margin-left: calc(-1 * var(--bs-gutter-x,.75rem));
    background: var(--background-light);
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
}
.lid{
    background: #F6F8FA;
    padding: 12px;
    border-radius: 3px;
}
#dash-nav, #dash-content{
    background: var(--background-light)
}
.card{
    background: #fff;
    padding: 20px;
    margin-bottom: 1rem;
    border-radius: 20px;
    border: none
}
.form-control{
    padding: 12px
}
@media screen and (max-width: 768px){
    #submit-button{
        width: 100%;
    }
    .card-body{
        padding: 0
    }
    .card{
         padding: 12px
    }
    #personen-grid{
        grid-template-columns: 1fr;
        padding: 12px 24px;
    }
    #personen-grid{
      grid-gap: 0.7rem;
  }
}
.form-label span{
    color: red;
}
#gezinslid-toevoegen-knop{
  margin-bottom: -7px;
}
#gezinslid-toevoegen{
  padding: 24px;
  border: 1px solid var(--background-light);
  margin-bottom: 1.5rem;
  border-radius: 10px;
}
</style>
